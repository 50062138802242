import { effect, inject } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { SiteConfigSEO } from '@app/core/entities/site-config.interface';
import { useSiteConfig } from '@services/site-config.service';

const socialPrefixes = {
  Facebook: 'og',
  Twitter: 'twitter',
};

const isTagNotEmpty = (tag: { name: string; content: string | null }): tag is { name: string; content: string } =>
  !!tag.content;

export const getSeoTags = (seo: SiteConfigSEO) =>
  [
    {
      name: 'description',
      content: seo.metaDescription,
    },
    {
      name: 'keywords',
      content: seo.keywords,
    },
  ].filter(isTagNotEmpty);

export const getSocialTags = (seo: SiteConfigSEO) => {
  const socialTags = [];
  for (let item of seo.metaSocials) {
    const prefix = socialPrefixes[item.socialNetwork];
    socialTags.push(
      {
        name: `${prefix}:title`,
        content: item.title,
      },
      {
        name: `${prefix}:description`,
        content: item.description,
      },
      {
        name: `${prefix}:image`,
        content: item.imageUrl,
        itemprop: 'image',
      },
    );
  }
  return socialTags.filter(isTagNotEmpty);
};

export const setMetaSEO = () => {
  const meta = inject(Meta);
  const siteConfig = useSiteConfig();

  effect(() => {
    const seo = siteConfig()?.seo;
    if (seo) {
      meta.addTags(getSeoTags(seo));
      meta.addTags(getSocialTags(seo));
    }
  });
};
